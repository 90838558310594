<template>
  <div v-if="pageContent" class="confirm-details">
    <h1 class="confirm-details__title h4">
      Step {{ currentStepNumber }}: {{ pageContent.stepTitle }}
    </h1>
    <div class="booking-details__wrapper">
      <div class="booking-details">
        <div class="booking-details__heading">
          <p class="booking-details__heading-text">
            {{ pageContent.dateAndTimeHeading }}
          </p>
          <a
            ref="edit-appointment"
            class="booking-details__heading-edit"
            href="javascript:;"
            @click="$emit('go-specific', `${appointmentType}-book-appointment`)"
          >
            <span>Edit</span>
            <i class="booking-details__heading-edit-icon icon icon-pen"></i>
          </a>
        </div>
        <p ref="appointment-date">{{ selectedAppointmentDate }}</p>
        <p ref="appointment-time">{{ selectedAppointmentTime }}</p>
      </div>

      <div class="booking-details">
        <div class="booking-details__heading">
          <p class="booking-details__heading-text">
            {{ pageContent.atHomeHeading }}
          </p>
          <a
            ref="edit-contact"
            class="booking-details__heading-edit"
            href="javascript:;"
            @click="$emit('go-specific', `${appointmentType}-contact-details`)"
          >
            <span>Edit</span>
            <i class="booking-details__heading-edit-icon icon icon-pen"></i>
          </a>
        </div>
        <p ref="contact-name" class="booking-details__name">
          {{ form.firstName }} {{ form.lastName }}
        </p>
        <p ref="contact-phone">{{ contactPhone }}</p>
        <p ref="contact-email">{{ contactEmail }}</p>
      </div>

      <div class="booking-details">
        <div class="booking-details__heading">
          <p class="booking-details__heading-text">
            {{ pageContent.electricalEquipmentHeading }}
          </p>
          <a
            ref="edit-medical-equipment"
            class="booking-details__heading-edit"
            href="javascript:;"
            @click="$emit('go-specific', `${appointmentType}-contact-details`)"
          >
            <span>Edit</span>
            <i class="booking-details__heading-edit-icon icon icon-pen"></i>
          </a>
        </div>
        <p>{{ hasMedicalEquipment ? "Yes" : "No" }}</p>
      </div>
    </div>

    <FeedbackDialog
      :header="pageContent.feedbackDialogTitle"
      class="confirm-details__feedback-dialog"
      variant="info"
    >
      {{ pageContent.feedbackDialogText }}
    </FeedbackDialog>

    <div class="confirm-details__button-wrapper">
      <AppButton
        v-bind="cmsBlockToProps(pageContent.submitButton)"
        ref="confirm-button"
        data-dd-action-name="Book Appointment Button"
        :loading="submitStatus === 'PENDING'"
        :disabled="submitStatus === 'PENDING'"
        class="confirm-details__button"
        @click="$emit('go-forward')"
      />
    </div>
  </div>

  <Modal
    v-bind="bookingErrorModalProps"
    :is-modal-open="isErrorModalOpen"
    @close-modal="isErrorModalOpen = false"
  >
    <template #cancelButton>
      {{ bookingErrorModalProps.cancelBtnText }}
    </template>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import FeedbackDialog from "@soenergy/frontend-library/src/components/FeedbackDialog.vue"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import Modal from "@soenergy/frontend-library/src/components/Modal.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import confirmDetailsContent from "soenergy-cms-loader!?path=smbp/confirm-details"

export default {
  components: {
    FeedbackDialog,
    AppButton,
    Modal,
  },
  mixins: [cmsPreviewMixin({ story: confirmDetailsContent })],
  props: {
    currentStepNumber: {
      type: Number,
      default: null,
    },
  },
  emits: ["go-specific", "go-forward"],
  data() {
    return {
      isErrorModalOpen: false,
    }
  },
  computed: {
    ...mapGetters([
      "selectedAppointmentDate",
      "selectedAppointmentTime",
      "contactPhone",
      "contactEmail",
      "hasMedicalEquipment",
    ]),
    ...mapState({
      submitStatus: (state) => state.submitStatus,
      submitError: (state) => state.submitError,
      form: (state) => state.homeDuringAppointment.form,
    }),
    bookingErrorModalProps() {
      if (!this.pageContent) return null

      const { bookingSlotError, bookingGenericError } = this.pageContent
      const isSlotUnavailableError = this.submitError
        ?.toLowerCase()
        .includes("selected date is no longer available")
      const errorModalContent = isSlotUnavailableError
        ? bookingSlotError
        : bookingGenericError

      const { header, body, cancelBtnText } =
        this.cmsBlockToProps(errorModalContent)

      return {
        header,
        body: this.cmsBlockToProps(body),
        cancelBtnText: cancelBtnText || "Close",
      }
    },
    appointmentType() {
      return this.$route.meta.appointmentType
    },
  },
  watch: {
    submitStatus() {
      if (this.submitStatus === "ERROR") {
        this.isErrorModalOpen = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.confirm-details {
  padding-bottom: $space-17;

  &__title {
    text-transform: none;
  }

  &__button-wrapper {
    display: flex;
    gap: $space-4;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: $space-4;
    padding: 0 $space-4;
    z-index: zindex(sticky);

    @include md {
      position: static;
      padding: 0;
      margin-top: $space-11;
    }
  }

  &__button {
    width: 100%;
  }

  &__feedback-dialog {
    margin-top: $space-8;
    width: 100%;

    @include lg {
      margin-top: $space-10;
    }
  }
}

.booking-details {
  margin-bottom: $space-8;
  font-size: $font-size-4;

  @include lg {
    margin-bottom: $space-4;
    line-height: $line-height-4;
  }

  &__wrapper {
    margin-top: $space-8;

    @include lg {
      margin-top: $space-11;
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-3;
    align-items: flex-start;
  }

  &__heading-text {
    font-weight: $weight-medium;
    font-size: $font-size-5;
  }

  &__heading-edit {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: $space-3;
    font-size: $font-size-3;
    line-height: calc($font-size-5 * $line-height-4);

    &:hover {
      text-decoration: none;
    }
  }

  &__heading-edit-icon {
    margin-left: $space-2;
    font-weight: $weight-bold;
    font-size: initial;
  }

  &__name {
    font-weight: $weight-medium;
  }
}
</style>
